.review-table {
  @apply w-full;
  // thead
  &__title-name-data {
    @apply w-1/5 text-left bg-red-800 text-white py-4 px-2;
  }
  &__title-present-data {
    @apply w-2/5 text-left bg-red-800 text-white py-4 px-2;
  }
  &__title-changed-data {
    @apply w-2/5 text-left bg-red-800 text-white py-4 px-2;
  }

  // tbody
  &__name-data {
    @apply text-black p-2;
  }
  &__present-data {
    @apply text-black p-2;
    &--alert {
      @apply text-red-800 p-2;
    }
  }
  &__changed-data {
    @apply text-black p-2;
    &--alert {
      @apply p-2;
      color: #4caf50;
    }
  }
}
