.upload-image-card--uploading {
  justify-content: center;
  align-items: center;

  &__spinner {
    position: absolute;
    z-index: 1;
  }

  &__card {
    opacity: 0.4;
    position: relative;
  }
}
