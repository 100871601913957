.purchase-order {
  &__paper {
    background-color: rgba(245, 245, 245, 1);
  }
  &__card {
    @apply w-full;
  }
}

.purchase-order-products-table {
  &__product-cell {
    @apply flex items-center;
    &__info {
      @apply ml-2 inline-flex flex-col;
    }
  }
}
