.purchase-product-header {
  &__name {
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 300px;
  }

  &__code {
    color: rgba(0, 0, 0, 0.54);
  }

  &__type {
    &--product {
      color: #8e0000;
    }
    &--service {
      color: #C79400;
    }
  }
}
