.product-pair {
    &__product-cell {
      @apply flex items-center;
      &__info {
        @apply ml-2 inline-flex flex-col;
      }
    }
  }

  .product-pair-dialog {
    &__product-cell {
      @apply flex items-center;
      &__info {
        @apply ml-2 inline-flex flex-col;
      }
    }
  }