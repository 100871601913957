.MuiAvatar-root.signal {
  @apply w-8 h-8;

  .MuiSvgIcon-root.signal__icon {
    font-size: 1.125rem;
  }
}

.MuiAvatar-root.signal-v2 {
  @apply w-8 h-8;
  background-color: #F44336;
  color: #5d0c28;

  .MuiSvgIcon-root.signal__icon {
    font-size: 1.125rem;
  }
}

.MuiAvatar-root.signal-small {
  @apply w-6 h-6;

  .MuiSvgIcon-root.signal__icon {
    font-size: 1rem;
  }
}

.MuiAvatar-root.signal-success {
  background-color: #7bc67e;
  color: #1e4620;
}

.MuiAvatar-root.signal-warning {
  background-color: #ffb547;
  color: #663d00;
}

.MuiAvatar-root.signal-error {
  background-color: #f88078;
  color: #5d0c28;
}

.MuiAvatar-root.signal-black {
  background-color: rgba(0, 0, 0, 0.87);
}

.MuiAvatar-root.signal-yellow {
  background-color: #C79400;
}

.MuiAvatar-root.signal-transparent{
  background-color: transparent;
}