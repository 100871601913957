.document-card {
  @apply pr-2 pb-2;
  &__box {
    @apply pl-4 w-full;
    &__title {
      @apply flex justify-between items-center;
    }
    &__content {
      @apply flex justify-between items-center;
    }
  }
}

.document-image {
  @apply cursor-pointer;
}
