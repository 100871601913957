.payment-requisition-verification {
  &__check-list {
    &__header {
      @apply mb-4 flex justify-between;
    }

    &__list {
      @apply list-disc;
      color: rgba(0, 0, 0, 0.54);
    }
  }
}
