.table {
  &--white {
    background-color: white;
  }
}

.table-row-inactive {
  opacity: 0.5;
  background-color: rgba(0, 0, 0, 0.04);
}

.table-row-hide {
  display: none !important;
}
