.transport-task-icon {
  @apply inline-flex items-center justify-center;
  @apply h-8 w-8;
  color: rgba(0, 0, 0, 0.12);
  > .MuiSvgIcon-root {
    @apply h-8 w-8;
  }

  &--processing {
    color: #ff9800;
  }

  &--pending {
    color: #00ccff;
  }

  &--success {
    color: #4caf50;
  }

  &--failed {
    color: #f44336;
  }

  &--not-found {
    color: #929292;
    opacity: 0.1;
  }

  &--small {
    @apply h-6 w-6;
    > .MuiSvgIcon-root {
      @apply h-6 w-6;
    }
  }
}
