.signal-cell {
  @apply flex;
  &__signal {
    @apply mr-2;

    &:last-child {
      @apply mr-0;
    }
  }
}
