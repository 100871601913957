.button {
  padding-top: 8px;
  padding-bottom: 8px;
}

.list {
  max-width: 300px;
  min-width: 240px;
}

.list-item {
  &.MuiTypography-displayBlock {
    display: flex;
    flex-wrap: wrap;
  }
}
