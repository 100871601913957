.Avatar.vendor-profile {
  @apply md:w-20 md:h-20 lg:w-40 lg:h-40 rounded-lg;
}

#vendor-profile-card {
  border-top: 2rem #c79400 solid;
  &__code-title {
    @apply flex items-center;
    > svg {
      @apply ml-2;
      color: #d1b000;
    }
  }

  &__action-button {
    svg {
      @apply ml-2;
    }
  }

  &__contact-info {
    @apply mt-4;
  }
}
