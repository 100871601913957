.sale-order-create-page-header {
  position: sticky;
  background: white;
  opacity: 0.9;
  width: 1300px;
  top: 5%;
  height: 75px;
  display: flex;
  align-items: center;
  z-index: 1;
}
