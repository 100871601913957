@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
}

* {
  box-sizing: border-box;
}

.MuiInputBase-root {
  &.Mui-disabled {
    overflow: hidden;
    > input {
      background: rgba(0, 0, 0, 0.12);

      &:hover {
        cursor: not-allowed;
      }
    }
  }
}
