.transport-shipping-daily-summary-products {
  &__product-item {
    @apply flex items-center mt-2;
    &__info {
      @apply ml-2 flex-col w-full;
    }
  }
}


.summary-row {
  > td {
    @apply font-bold;

    > .MuiTypography-root {
      @apply font-bold;
    }
  }
}