.purchase-requisition-box {
  &__vendor-card,
  &__purchase-product,
  &__purchase-order-list,
  &__attachment,
  &__activity,
  &__card {
    height: 100%;
    &__title {
      &.MuiTypography-root {
        @apply mb-4;
      }
    }
  }

  &__check-list {
    &__header {
      @apply mb-4 flex justify-between;
    }

    &__list {
      @apply list-disc;
      color: rgba(0, 0, 0, 0.54);
    }
  }
}

.purchase-requisition-products-table {
  &__product-cell {
    @apply flex items-center;
    &__info {
      @apply ml-2 inline-flex flex-col;
    }
  }
}
