.file-card {
  height: 222px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;

  &__close-icon{
    position: absolute;
    right: 4px;
    margin-top: 5px;
  }
}
